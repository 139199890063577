import * as React from "react"
import CaseStudy1 from "../components/case-study1/CaseStudy1"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CaseStudy1Page = () => (
  <Layout>
    <Seo title="Case Study" />
    <CaseStudy1 />
  </Layout>
)

export default CaseStudy1Page

import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { CgClose } from "react-icons/cg"
import { AiFillLock } from "react-icons/ai"
import { ImSmile2 } from "react-icons/im"

export default function CaseStudy1Modal2({ modalIsOpen, toggleModal }) {
  const [firstName, setFirstName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")

  const [firstNameErr, setFirstNameErr] = useState({})
  const [emailAddressErr, setEmailAddressErr] = useState({})

  if (!modalIsOpen) return null
  const syncUser = async () => {
    const config = {
      method: "post",
      url: "/.netlify/functions/ac-contact-sync",
      data: {
        email: emailAddress,
        first_name: firstName,
        list: 20,
        status: 1,
        tags: "api-nlc-free-training",
      },
    }

    try {
      const response = await axios(config)
      // console.log(response.data)
      if (response) return true
    } catch (err) {
      console.log(`SyncUser ${err}`)
    }
  }
  //END Add data to ActiveCampaign

  const onSubmit = async e => {
    e.preventDefault()
    const isValid = formValidation()

    if (isValid) {
      const data = {
        first_name: firstName,
        email_address: emailAddress,
      }

      //Add data to ActiveCampaign
      syncUser()
      console.log(data)
      window.location.replace("nlc-free-training")
    }
  }

  const formValidation = () => {
    const firstNameErr = {}
    const emailAddressErr = {}
    let isValid = true

    if (firstName.trim().length < 1) {
      firstNameErr.firstNameShort = "Please enter your first name."
      isValid = false
    }

    if (emailAddress.trim().length < 1) {
      emailAddressErr.emailAddressShort = "Please a valid email address."
      isValid = false
    }

    if (emailAddress.trim().length > 110) {
      emailAddressErr.emailAddressShort = "Please a valid email address."
      isValid = false
    }

    if (!emailAddress.includes("@") || !emailAddress.includes(".")) {
      emailAddressErr.emailAddressShort = "Please a valid email address."
      isValid = false
    }

    setFirstNameErr(firstNameErr)
    setEmailAddressErr(emailAddressErr)
    return isValid
  }

  return (
    <>
      <SuccessModalOverlay>
        <SuccessModalWrapper>
          <SuccessModalContainer>
            <CloseButtonContainer>
              <CloseIcon onClick={toggleModal} />
            </CloseButtonContainer>
            <BasicH1>
              Enter your email to get instant access to the case study
            </BasicH1>
            <InputContainer>
              <input
                type="text"
                id="first-name"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value)
                }}
                className="input input-success case-study1__input"
                placeholder="Enter your first name"
              />
              <br />
              {Object.keys(firstNameErr).map(key => {
                return (
                  <ErrorDiv key={key}>
                    {firstNameErr[key]}
                    <WinkIcon />
                  </ErrorDiv>
                )
              })}
              <input
                type="text"
                id="emailAddress"
                value={emailAddress}
                onChange={e => {
                  setEmailAddress(e.target.value)
                }}
                className="input input-success case-study1__input"
                placeholder="Enter your email"
              />
              <br />
              {Object.keys(emailAddressErr).map(key => {
                return (
                  <ErrorDiv key={key}>
                    {emailAddressErr[key]}
                    <WinkIcon />
                  </ErrorDiv>
                )
              })}
            </InputContainer>
            <ButtonContainer>
              <button
                type="submit"
                className="btn btn-primary modal2__button"
                onClick={onSubmit}
              >
                Watch The Free Case Study Now
              </button>
            </ButtonContainer>
            <PromiseContainer>
              <LockIcon />
              Your information is 100% secure.
            </PromiseContainer>
          </SuccessModalContainer>
        </SuccessModalWrapper>
      </SuccessModalOverlay>
    </>
  )
}

const SuccessModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6000;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 550px) {
    /* background-color: white; */
  }
`
const SuccessModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`
const SuccessModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, auto);
  position: absolute;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 20px;
  top: 70px;
  max-width: 800px;
  min-height: auto;
  width: 80%;
  background-color: #fff;
  z-index: 5000;
  border-radius: 10px;

  @media (max-width: 1030px) {
  }

  @media (max-width: 550px) {
    width: 98%;
  }
`

const BasicH1 = styled.h1`
  display: block;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  text-align: left;
  font-weight: 900;
  color: black;
  padding-top: 30px;
  padding-left: 20px;
  margin: 0px;

  @media screen and (max-width: 420px) {
    font-size: 2rem;
  }
`
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  width: 100%;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const InputContainer = styled.div`
  width: 100%;
  padding: 20px;
`

const PromiseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 0.7rem;
  margin-top: 0.5rem;
`
const ErrorDiv = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-left: 1rem;
`
const LockIcon = styled(AiFillLock)`
  font-size: 0.8rem;
  color: black;
  margin-right: 0.3rem;
`
const WinkIcon = styled(ImSmile2)`
  font-size: 1rem;
  color: goldenrod;
  margin-left: 0.5rem;
  margin-right: 0.3rem;
`
const CloseIcon = styled(CgClose)`
  cursor: pointer;
  font-size: 1.5rem;
  color: #8a8a8a;
`
